import { render, staticRenderFns } from "./OrganizationSuccessMessage.vue?vue&type=template&id=7bb5f228&scoped=true&style=background-color%3A%20%23EAEAEA"
import script from "./OrganizationSuccessMessage.vue?vue&type=script&lang=js"
export * from "./OrganizationSuccessMessage.vue?vue&type=script&lang=js"
import style0 from "./OrganizationSuccessMessage.vue?vue&type=style&index=0&id=7bb5f228&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb5f228",
  null
  
)

export default component.exports