<template style="background-color: #EAEAEA">
    <div class="container">
        <div class="message-box">
            <div class="message">
                {{ $t('organization_message') }}
            </div>
            <div class="text-right mt-3 font-weight-bold">{{ $t('future_ai_summit_team') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessMessage',
    metaInfo() {
        return {
            title: this.$t("information"),
        };
    },
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.message-box {
    padding: 20px;
    border: 2px solid #333;
    border-radius: 8px;
    max-width: 80%;
    text-align: center;
}

.message {
    font-size: 18px;
    color: #333;
}
</style>
